const siteUrl = 'https://www.lotuscharlotte.com/'

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Lotus',
    siteUrl:'https://www.lotuscharlotte.com/',
    site_id: '60db876d6f396b7d7bb8c868',
    api_base_url: 'https://api.dealermasters.com:4500',
    lead_url: 'https://leads.dealermasters.com/leads',
    master_sites: ['63fd317f0439a71a0bd5b43b'],
    autocheck_url: 'https://n1u6ywp1bf.execute-api.us-east-2.amazonaws.com/default/AutoCheckFastLink',
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
      },
    },
    {
      resolve: "gatsby-plugin-emotion"
    },
    {
      resolve: "gatsby-transformer-json"
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        cssLoaderOptions: {
          camelCase: false,
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `src`,
        path: `${__dirname}/src/`
      }
    },
    {
      resolve: 'gatsby-plugin-s3',
      options: {
        bucketName: 'mxs-02db94bf-3f72-4f8b-9fe9-5faaf9fb6030-prodsite'
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'inventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['129658','1296589']
      }
    },
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'altInventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: []
      }
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/favicon.png'
      }
    },{
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.lotuscharlotte.com/',
        sitemap: 'https://www.lotuscharlotte.com//sitemap-pages.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    }
  ]
}